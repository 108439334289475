<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-body>
            <b-form-group
              label="Araç Kartı Ara"
              label-for="search"
            >
              <b-input-group>
                <b-form-input
                  id="search"
                  v-model="search"
                  placeholder="Müşteri Adı, Araç Plaka, Şase No..."
                  @keydown.enter="searchData"
                />
                <b-input-group-append>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-50"
                    @click="searchData"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      class="mr-50"
                    />
                    Ara
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-card-body>
          <b-table
            striped
            hover
            responsive="sm"
            :fields="fields"
            :items="dataList"
          >
            <template #cell(name)="data">
              <div>{{ data.item.name }}</div>
              <div class="text-primary">
                <small>{{ data.item.company_name }}</small>
              </div>
            </template>
            <template #cell(brand)="data">
              {{ data.item.brand }} {{ data.item.model }} {{ data.item.myear }}
              <div class="text-warning font-small-2">
                {{ data.item.license_plate }}
              </div>
            </template>
            <template #cell(control)="data">
              <b-button
                :to="'/damage-tracking/add/' + data.item.id_com_customer"
                variant="outline-primary"
                block
                size="sm"
              >
                Kayıt Oluştur
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BTable,
  BCardBody,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'CarSearch',
  components: {
    BButton,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BTable,
    BCardBody,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search: null,
      fields: [
        {
          key: 'name',
          label: 'MÜŞTERİ',
        },
        {
          key: 'brand',
          label: 'ARAÇ BİLGİSİ',
        },
        {
          key: 'control',
          label: 'Kontrol',
          tdClass: 'text-right width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          'com_customer.id AS id',
          'com_customer.company_name AS company_name',
          'com_customer.name AS name',
          'com_customer.phone AS phone',
          'com_customer.email AS email',
          'com_swap_brand.name AS brand',
          'com_swap_model.title AS model',
          'com_cars.hardware AS hardware',
          'com_cars.license_plate AS license_plate',
          'com_cars.myear AS myear',
          'com_cars.id_com_customer AS id_com_customer',
        ],
        order_by: ['id', 'DESC'],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['cars/getCars']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      this.dataQuery.or_like = {
        'com_customer.company_name': this.search,
        'com_customer.name': this.search,
        'com_customer.phone': this.search,
        'com_cars.license_plate': this.search,
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('cars/carsList', this.dataQuery)
    },
  },
}
</script>
